import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchExperienceData } from "../services/apiService";
import { setPages } from "../features/expPageSlice";
import { setObjects } from "../features/expObjectSlice";
import LoadingSpinner from "./LoadingSpinner";
import SlideShow from "./SlideShow";
import "./ExperienceDetail.css";
import { useDispatch, useSelector } from "react-redux";
const ExperienceDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.accessToken);
  const [experience, setExperience] = useState(null);
  const [slides, setSlides] = useState([]);
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const getExperienceData = async () => {
      console.log("experience id_access_token", id, access_token);

      setLoading(true);
      try {
        const data = await fetchExperienceData(
          "test",
          `${id}`,
          `${access_token}`
        );
        const response_data = JSON.parse(data);
        setExperience(response_data.data);
        const slides_data = JSON.parse(response_data.data.json_data);
        setSlides(slides_data.ExpPages);
        setModels(slides_data.ExpObjects);
        dispatch(setPages(slides_data.ExpPages));
        dispatch(setObjects(slides_data.ExpObjects));
      } catch (error) {
        setError("Failed to fetch experience details");
      }
      setLoading(false);
    };

    getExperienceData();
  }, [id]);

  if (loading) return <LoadingSpinner />;
  if (error) return <p>{error}</p>;

  return (
    <div>
      {experience ? (
        <div style={{ padding: "200px", fontSize: "1.25rem" }}>
          <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
            <h1 style={{ color: "#794df0" }}>{experience.name}</h1>
            <img
              src={`data:image/png;base64,${experience.icon}`}
              alt="icon"
              style={{
                width: 150,
                height: 150,
                borderRadius: 10,
              }}
            />
          </div>
          <button onClick={() => navigate(`/view3dexperience/${id}`)}>
            View Demo
          </button>
          {/* <SlideShow slides={slides} models={models} /> */}
        </div>
      ) : (
        <p>No experience details available</p>
      )}
    </div>
  );
};

export default ExperienceDetail;
