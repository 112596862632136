import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchExperienceData } from "../services/apiService";
import LoadingSpinner from "./LoadingSpinner";
import SlideShow from "./SlideShow";
import { useSelector } from "react-redux";

const ViewExperience = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const access_token = useSelector((state) => state.auth.accessToken);

  useEffect(() => {
    const getExperienceData = async () => {
      setLoading(true);
      try {
        const data = await fetchExperienceData(
          "test",
          `${id}`,
          `${access_token}`
        );
        const response_data = JSON.parse(data);

        const slides_data = JSON.parse(response_data.data.json_data);
        console.log("slides data", slides_data);
      } catch (error) {
        setError("Failed to fetch experience details");
      }
      setLoading(false);
    };

    getExperienceData();
  }, [id]);

  if (loading) return <LoadingSpinner />;
  if (error) return <p>{error}</p>;
  return <SlideShow />;
};
export default ViewExperience;
