import JSZip from "jszip";
import { fetchAssetZip } from "../services/apiService";

export const fetchAndUnzipModel = async (
  tenant_slug,
  uuid,
  asset_uuid,
  token
) => {
  try {
    const data = await fetchAssetZip(tenant_slug, uuid, asset_uuid, token);
    const zip = await JSZip.loadAsync(data);

    let modelUrl = null;

    const filePromises = [];
    let filename = "";
    zip.forEach((relativePath, zipEntry) => {
      if (zipEntry.name.endsWith(".fbx")) {
        console.log("file name", zipEntry.name);
        filename = zipEntry.name;
        const modelPromise = zipEntry.async("blob").then((blobData) => {
          console.log(`Model File ${zipEntry.name} extracted successfully.`);
          modelUrl = URL.createObjectURL(blobData);
        });
        filePromises.push(modelPromise);
      }
    });

    await Promise.all(filePromises);

    if (modelUrl) {
      return { modelUrl, filename };
    } else {
      throw new Error("No model file found in the ZIP");
    }
  } catch (error) {
    console.error("Error fetching and unzipping model:", error);
    throw error;
  }
};
