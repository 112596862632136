// api.js
import axios from "axios";

export const authenticateWithServer = async (idToken) => {
  try {
    const response = await axios.post(
      " https://api.tstvixr.in/v2/wonder/user/login",
      {
        id_tkn: idToken,
      }
    );
    console.log("response", response);
    return response.data.data.access_token;
  } catch (error) {
    console.error("Error authenticating with server:", error);
    throw error;
  }
};
