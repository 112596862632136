// Voiceover.jsx
import React, { useRef, useEffect } from "react";
import { useThree } from "@react-three/fiber";
import * as THREE from "three";

const Voiceover = ({ url, position, loop = false, isPlaying }) => {
  const audioRef = useRef();
  const { camera } = useThree();

  useEffect(() => {
    // Initialize the listener and audio if not already done
    if (!audioRef.current) {
      const listener = new THREE.AudioListener();
      const audio = new THREE.PositionalAudio(listener);
      camera.add(listener);
      audioRef.current = { listener, audio };
    }

    const { audio } = audioRef.current;

    // Load the audio file
    const loader = new THREE.AudioLoader();
    loader.load(
      url,
      (buffer) => {
        audio.setBuffer(buffer);
        audio.setRefDistance(10);
        audio.setLoop(loop);
        audio.setVolume(0.5);
        if (isPlaying) {
          audio.play();
        } else {
          audio.stop();
        }
      },
      undefined,
      (error) => {
        console.error("An error occurred while loading the audio", error);
      }
    );

    // Cleanup function to stop the audio when the URL changes or component unmounts
    return () => {
      if (audio.isPlaying) {
        audio.stop();
      }
    };
  }, [url, loop, camera, isPlaying]);

  if (!audioRef.current) {
    return null; // Prevent rendering until audioRef is initialized
  }

  return <primitive object={audioRef.current.audio} position={position} />;
};

export default Voiceover;
// import React, { useRef, useEffect } from "react";
// import { useThree } from "@react-three/fiber";
// import * as THREE from "three";

// const Voiceover = ({ url, position, loop = false, isPlaying }) => {
//   const audioRef = useRef();
//   const { camera } = useThree();

//   useEffect(() => {
//     if (!audioRef.current) {
//       const listener = new THREE.AudioListener();
//       const audio = new THREE.PositionalAudio(listener);
//       camera.add(listener);
//       audioRef.current = { listener, audio };
//     }

//     const { audio } = audioRef.current;

//     const loader = new THREE.AudioLoader();
//     loader.load(
//       url,
//       (buffer) => {
//         audio.setBuffer(buffer);
//         audio.setRefDistance(10);
//         audio.setLoop(loop);
//         audio.setVolume(0.5);
//         if (isPlaying) {
//           audio.play();
//         } else {
//           audio.stop();
//         }
//       },
//       undefined,
//       (error) => {
//         console.error("An error occurred while loading the audio", error);
//       }
//     );

//     return () => {
//       if (audio.isPlaying) {
//         audio.stop();
//       }
//     };
//   }, [url, loop, camera, isPlaying]);

//   if (!audioRef.current) {
//     return null;
//   }

//   return <primitive object={audioRef.current.audio} position={position} />;
// };

// export default Voiceover;
