// src/components/Experience.js
import React, { useEffect, useRef, useState } from "react";
import { Vector3, Quaternion, Euler } from "three";
import { useFrame, useLoader } from "@react-three/fiber";

import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import {
  PerspectiveCamera,
  useTexture,
  ContactShadows,
} from "@react-three/drei";
import { Plane } from "@react-three/drei";
import { Suspense } from "react";
import { Color, MeshStandardMaterial } from "three";
import { createXRStore } from "@react-three/xr";
import { ObjectBehaviourType } from "../Constants/ObjectBehaviourType";

import { useSpring, animated } from "@react-spring/three";

import { extractKeyframes } from "./keyFameLogic";
import Label from "./Label";
import loadModel from "../utils/modelLoader";

//import AnimatedMesh from "./AnimatedChild";

const keyframes_down_support = [
  {
    position: [-0.740390062, 8.843006, -0.0158240274],
    rotation: [0, 0, 0],
    scale: [1, 1, 1],
    delay: 0.5,
    time: 0,
  },
  {
    position: [-0.7404282, -4.5938673, -0.015824046],
    rotation: [0, 0, 0],
    scale: [1, 1, 1],
    delay: 0.5,
    time: 0,
  },
  {
    position: [-0.7404282, 8.843006, -0.015824046],
    rotation: [0, 0, 0],
    scale: [1, 1, 1],
    delay: 0.5,
    time: 0,
  },
];

const Experience = ({
  modelUrl,
  ExpObjectBehaviouralProperties,
  filename,
  model_3d_parts,
  ExpPageInteractionData,
}) => {
  const [behaviourExp, setbehaviourExp] = useState(
    ExpObjectBehaviouralProperties
  );

  // State to track previous values
  const [prevPosition, setPrevPosition] = useState([0, 0, 0]);
  const [prevScale, setPrevScale] = useState([1, 1, 1]);
  const [prevRotation, setPrevRotation] = useState([0, 0, 0]);
  function componentToHex(c) {
    const hex = Math.round(c * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  // Access and animate a specific child of the model
  useEffect(() => {
    if (ExpObjectBehaviouralProperties) {
      setbehaviourExp(ExpObjectBehaviouralProperties);
    }
  }, [ExpObjectBehaviouralProperties]);

  const Model = () => {
    const texture = useTexture("../images/metal1.png");
    console.log("modelUrl", modelUrl, filename);
    // Reference to the model
    const groupRef = useRef();
    // Extract properties from JSON
    const expObject = ExpObjectBehaviouralProperties; // Assuming the first object for simplicity
    const expPropertyPosition =
      behaviourExp?.ExpObjectProperty?.ExpObjectTransformProperties
        ?.ObjectTransformPosition;
    const expPropertyPositionOffset =
      behaviourExp?.ExpObjectProperty?.ExpObjectTransformProperties
        ?.ObjectTransformPositionOffset;
    console.log("Main model positions", expPropertyPosition);
    const expPropertyScale =
      expObject?.ExpObjectProperty?.ExpObjectTransformProperties
        ?.ObjectTransformScale;
    const expPropertyRotation =
      expObject?.ExpObjectProperty?.ExpObjectTransformProperties
        ?.ObjectTransformRotation;
    console.log("Inside model component parts are", model_3d_parts);
    const model = loadModel(modelUrl, filename);
    // const model = useLoader(FBXLoader, modelUrl);

    const modelRef = useRef();

    // const childRef = useRef();

    // const animationProps = useSpring({
    //   from: {
    //     position: animationKeyFrames[0].position,
    //     rotation: animationKeyFrames[0].rotation,
    //     scale: animationKeyFrames[0].scale,
    //   },
    //   to: animationKeyFrames.map((kf) => ({
    //     position: kf.position,
    //     rotation: kf.rotation,
    //     scale: kf.scale,
    //     config: { duration: kf.delay * 1000 }, // Convert delay to milliseconds
    //   })),
    //   loop: true,
    // });

    // useEffect(() => {
    //   if (ExpPageInteractionData != null) {
    //     if (modelRef.current) {
    //       const childObjectDown =
    //         modelRef.current.getObjectByName("Down_Support");
    //       // const childObject = modelRef.current.getObjectByName("Spring");
    //       // const childObjectRod = modelRef.current.getObjectByName("Inside_Rod");
    //       // const childObjectUpside = modelRef.current.getObjectByName("Upside");
    //       if (childObjectDown) {
    //         // childRef.current = childObject;
    //         childRefDown.current = childObjectDown;
    //         // childRefRod.current = childObjectRod;
    //         // childRefUpside.current = childObjectUpside;
    //       } else {
    //         console.error('Child object "Spring" not found.');
    //       }
    //     }
    //   }
    // }, [modelRef.current]);
    useFrame(() => {
      // if (childRef.current) {
      //   const { position, rotation, scale } = animationProps;
      //   if (position) childRef.current.position.set(...position.get());
      //   if (rotation) childRef.current.rotation.set(...rotation.get());
      //   if (scale) childRef.current.scale.set(...scale.get());
      //   childRef.current.updateMatrix();
      // }
      // if (ExpPageInteractionData != null) {
      //   if (childRefDown.current) {
      //     const { position, rotation, scale } = animationPropsDown;
      //     if (position) childRefDown.current.position.set(...position.get());
      //     if (rotation) childRefDown.current.rotation.set(...rotation.get());
      //     if (scale) childRefDown.current.scale.set(...scale.get());
      //     childRefDown.current.updateMatrix();
      //   }
      // }
      // if (childRefRod.current) {
      //   const { position, rotation, scale } = animationPropsRod;
      //   if (position) childRefRod.current.position.set(...position.get());
      //   if (rotation) childRefRod.current.rotation.set(...rotation.get());
      //   if (scale) childRefRod.current.scale.set(...scale.get());
      //   childRefRod.current.updateMatrix();
      // }
      // if (childRefUpside.current) {
      //   const { position, rotation, scale } = animationPropsDown;
      //   if (position) childRefUpside.current.position.set(...position.get());
      //   if (rotation) childRefUpside.current.rotation.set(...rotation.get());
      //   if (scale) childRefUpside.current.scale.set(...scale.get());
      //   childRefUpside.current.updateMatrix();
      // }
    });
    // Log the animation properties
    // useEffect(() => {
    //   console.log("Animation Props:", animationProps);
    // }, [animationProps]);
    // useEffect(() => {
    //   if (fbx) {
    //     console.log("FBX Model Loaded:", fbx);
    //   }
    // }, [fbx]);
    // useEffect(() => {
    //   if (modelRef.current) {
    //     const childObject = modelRef.current.getObjectByName("Spring");
    //     console.log("Child Object:", childObject);
    //   }
    // }, [modelRef.current]);
    // useEffect(() => {
    //   if (modelRef.current) {
    //     const childObject = modelRef.current.getObjectByName("Spring");
    //     if (childObject) {
    //       let angle = 0;
    //       const animate = () => {
    //         angle += 0.01;
    //         childObject.rotation.y = angle;
    //         childObject.updateMatrix();
    //         requestAnimationFrame(animate);
    //       };
    //       animate();
    //     }
    //   }
    // }, [modelRef.current]);
    // useEffect(() => {
    //   if (modelRef.current) {
    //     const childObject = modelRef.current.getObjectByName("Spring");
    //     if (childObject) {
    //       animationProps.rotation.to((rotation) => {
    //         childObject.rotation.set(rotation[0], rotation[1], rotation[2]);
    //         childObject.updateMatrix();
    //       });
    //     } else {
    //       console.error('Child object "Spring" not found.');
    //     }
    //   }
    // }, [animationProps.rotation, modelRef.current]);
    // fbx.position.set(
    //   expPropertyPosition.x,
    //   expPropertyPosition.y,
    //   expPropertyPosition.z
    // );
    // fbx.scale.set(
    //   expPropertyScale.x / 10,
    //   expPropertyScale.y / 10,
    //   expPropertyScale.z / 10
    // );
    // fbx.rotation.set(
    //   expPropertyRotation.x,
    //   expPropertyRotation.y,
    //   expPropertyRotation.z
    // );
    // Create animated transformations
    // Combine base position and offset
    // Combine base position and offset
    const finalPosition = new Vector3(
      expPropertyPosition.x + expPropertyPositionOffset.x,
      expPropertyPosition.y + expPropertyPositionOffset.y,
      expPropertyPosition.z + expPropertyPositionOffset.z
    );

    // Assuming expPropertyRotation provides the rotation in degrees
    const quaternion = new Quaternion().setFromEuler(
      new Euler(
        expPropertyRotation.x * (Math.PI / 180), // Negate if needed
        expPropertyRotation.y * (Math.PI / 180), // Negate if needed
        expPropertyRotation.z * (Math.PI / 180) // Negate if needed
      )
    );
    const { position, scale, rotation } = useSpring({
      from: {
        position: [0, 0, 0],
        scale: [1, 1, 1],
        rotation: [0, 0, 0],
      },
      to: {
        position: [finalPosition.x, finalPosition.y, finalPosition.z],
        scale: [expPropertyScale.x, expPropertyScale.y, expPropertyScale.z],
        rotation: [
          expPropertyRotation.x * (Math.PI / 180),
          expPropertyRotation.y * (Math.PI / 180),
          expPropertyRotation.z * (Math.PI / 180),
        ],
      },
      config: {
        duration: 2000, // duration in milliseconds (2 seconds)
      },
    });
    useEffect(() => {
      model.traverse((child) => {
        if (child.isMesh) {
          const matchedPart = model_3d_parts.find(
            (part) => part.ExpObjectName === child.name
          );
          // Animation logic
          // if (ExpPageInteractionData != null) {
          //   const expObjectInteractionDetails =
          //     matchedPart.ExpObjectInteractionDetails;
          //   if (expObjectInteractionDetails) {
          //     const animationData =
          //       expObjectInteractionDetails[0]?.ExpObjectBehaviourAnimationData
          //         ?.ActiveInteractionsAllKeyframe;
          //     console.log("Animation data", animationData);
          //     if (animationData) {
          //       const keyframes = extractKeyframes(animationData);
          //       // setAnimationKeyFrames(keyframes);
          //       console.log(`Keyframes ${child.name}`, keyframes);
          //       child.userData.keyframes = keyframes;
          //     }
          //   }
          // }

          if (matchedPart) {
            const matched_part_position =
              behaviourExp.ExpObjectProperty?.ExpObjectTransformProperties
                ?.ObjectTransformPosition;
            const matched_part_position_offset =
              behaviourExp.ExpObjectProperty?.ExpObjectTransformProperties
                ?.ObjectTransformPositionOffset;
            const matched_final_position = new Vector3(
              matched_part_position.x + matched_part_position_offset.x,
              matched_part_position.y + matched_part_position_offset.y,
              matched_part_position.z + matched_part_position_offset.z
            );
            const matched_part_rotation =
              behaviourExp.ExpObjectProperty?.ExpObjectTransformProperties
                ?.ObjectTransformRotation;
            const matched_part_scale =
              behaviourExp.ExpObjectProperty?.ExpObjectTransformProperties
                ?.ObjectTransformScale;
            const matched_part_color =
              matchedPart.ExpObjectBehaviouralProperties[0]?.ExpObjectProperty
                ?.ExpObjectMaterials[0].ExpMaterialColor;
            console.log("matched_part_position", matched_part_position);
            const hexColor = rgbToHex(
              matched_part_color.ExpColorRComponent,
              matched_part_color.ExpColorGComponent,
              matched_part_color.ExpColorBComponent
            );
            child.position.set(
              matched_final_position.x,
              matched_final_position.y,
              matched_final_position.z
            );

            child.rotation.set(
              matched_part_rotation.x * (Math.PI / 180),
              matched_part_rotation.y * (Math.PI / 180),
              matched_part_rotation.z * (Math.PI / 180)
            );
            child.scale.set(
              matched_part_scale.x,
              matched_part_scale.y,
              matched_part_scale.z
            );
            // If the child has materials
            if (Array.isArray(child.material)) {
              // Handle multiple materials (e.g., for different parts)
              child.material.forEach((material, index) => {
                console.log(
                  `Material ${index} color:`,
                  material.color.getHexString()
                );
              });
            } else {
              // Single material case
              console.log(
                `Single material color:`,
                `#${child.material.color.getHexString()}`
              );
              const defaultproperties = child.material;
              const newMaterial = new MeshStandardMaterial({
                color: new Color(hexColor),
                roughness: defaultproperties.roughness, // Adjust roughness
                metalness: defaultproperties.metalness, // Adjust metalness
                map: defaultproperties.texture,
              });

              child.material = newMaterial;
            }
          }
        }
      });
    }, [model, ExpObjectBehaviouralProperties]);
    useFrame(() => {
      // if (modelRef.current) {
      //   modelRef.current.children.forEach((child) => {
      //     if (child.isMesh) {
      //       // Apply position, scale, and quaternion rotation to child objects
      //       if (position) child.position.set(...position.get());
      //       if (scale) child.scale.set(...scale.get());
      //       if (rotation) {
      //         // Convert rotation from array to Quaternion
      //         const [x, y, z] = rotation.get(); // Assume rotation is in [x, y, z, w] format
      //         const quaternion = new Quaternion(x, y, z);
      //         child.rotation.setFromQuaternion(quaternion); // Apply quaternion rotation
      //       }
      //     }
      //   });
      // }
    });
    return (
      <animated.group
        ref={modelRef}
        position={position}
        scale={scale}
        rotation={rotation}
      >
        <primitive object={model} />
      </animated.group>
    );
  };

  return (
    <Suspense fallback={null}>
      <ambientLight intensity={0.8} />
      {/* <directionalLight position={[5, 5, 5]} intensity={0.8} /> */}
      <directionalLight
        position={[10, 10, 10]}
        intensity={1}
        castShadow
        shadow-mapSize-width={100}
        shadow-mapSize-height={100}
        shadow-camera-near={0.5}
        shadow-camera-far={500}
      />
      {/* <PerspectiveCamera makeDefault position={[5, 5, 5]} /> */}
      {modelUrl && <Model />}
      <ContactShadows
        opacity={1}
        scale={10}
        blur={1.5}
        resolution={512}
        far={10}
        color="#000000"
      />
    </Suspense>
  );
};

export default Experience;
