// src/slices/expObjectSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expObjects: [],
};

const expObjectSlice = createSlice({
  name: "expObject",
  initialState,
  reducers: {
    setObjects(state, action) {
      state.expObjects = action.payload;
      console.log("Objects are", state.expObjects);
    },
    updateObject(state, action) {
      const { objectId, updatedData } = action.payload;
      const index = state.expObjects.findIndex(
        (obj) => obj.ExpObjectId === objectId
      );
      if (index !== -1) {
        state.expObjects[index] = {
          ...state.expObjects[index],
          ...updatedData,
        };
      }
    },
  },
});

export const { setObjects, updateObject } = expObjectSlice.actions;

export const selectExpObjects = (state) => state.expObject.expObjects;

export default expObjectSlice.reducer;
