import JSZip from "jszip";
import { fetchAssetZip } from "../services/apiService";

export const fetchAndUnzipVoiceover = async (
  tenant_slug,
  uuid,
  asset_uuid,
  token
) => {
  try {
    const data = await fetchAssetZip(tenant_slug, uuid, asset_uuid, token);
    const zip = await JSZip.loadAsync(data);

    let voiceoverUrl = null;

    const filePromises = [];

    zip.forEach((relativePath, zipEntry) => {
      if (zipEntry.name.endsWith(".mp3") || zipEntry.name.endsWith(".wav")) {
        const voiceoverPromise = zipEntry.async("blob").then((blobData) => {
          console.log(
            `Voiceover File ${zipEntry.name} extracted successfully.`
          );
          voiceoverUrl = URL.createObjectURL(blobData);
        });
        filePromises.push(voiceoverPromise);
      }
    });

    await Promise.all(filePromises);

    if (voiceoverUrl) {
      return { voiceoverUrl };
    } else {
      throw new Error("No voiceover file found in the ZIP");
    }
  } catch (error) {
    console.error("Error fetching and unzipping voiceover:", error);
    throw error;
  }
};
