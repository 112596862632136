// import React, { useEffect, useState } from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
// } from "react-router-dom";
// import Login from "./components/Login";
// import Experience from "./components/Experience";
// import {
//   fetchExperienceData,
//   fetchExperienceList,
// } from "./services/apiService";
// import SlideShow from "./components/SlideShow";
// import "./styles.css";

// const App = () => {
//   const [slides, setSlides] = useState([]);
//   const [models, setModels] = useState([]);
//   const [experiences, setExperiences] = useState([]);
//   const [experienceName, setExperienceName] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   useEffect(() => {
//     const getExperienceList = async () => {
//       try {
//         const response = await fetchExperienceList(
//           "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNjU1NWU0Mjg0YmQwN2NjMTIzOGJjOWQxIiwic2NvcGUiOiJ0ZW5hbnQiLCJpYXQiOjE3MjE4ODQ2NTQsImV4cCI6MTcyMTk3MTA1NH0.f_nJSp5f1ArgEQyGzMskGIzN_bEM699CNPRAP_8jPK8"
//         );
//         console.log("Experience list----", response.data.items);
//         setExperiences(response.data.items);
//       } catch (error) {
//         console.log("error getting experience list", error);
//       }
//       setLoading(false);
//     };
//     const experienceData = async () => {
//       console.log("inside ftech experience data");
//       try {
//         const data = await fetchExperienceData(
//           "test",
//           "re-Cax6Ud2vGzRd6l-2",
//           "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNjU1NWU0Mjg0YmQwN2NjMTIzOGJjOWQxIiwic2NvcGUiOiJ0ZW5hbnQiLCJpYXQiOjE3MjE4ODQ2NTQsImV4cCI6MTcyMTk3MTA1NH0.f_nJSp5f1ArgEQyGzMskGIzN_bEM699CNPRAP_8jPK8"
//         );
//         const response_data = JSON.parse(data);
//         setExperienceName(response_data.data.name);
//         const slides_data = JSON.parse(response_data.data.json_data);
//         console.log("Experience data", slides_data);

//         //parse the json data here
//         setSlides(slides_data.ExpPages);
//         setModels(slides_data.ExpObjects);
//         console.log("model data", slides_data.ExpObjects);

//         // Create a URL for the Blob
//       } catch (error) {
//         console.error("Error fetching Experience data:", error);
//       }
//     };
//     getExperienceList();
//     experienceData();
//   }, []);
//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>{error}</p>;
//   return (
//     <div>
//       <div>
//         <h2>Experiences</h2>
//         <ul>
//           {experiences?.length === 0 && <li>No Experiences</li>}
//           {experiences?.map((experience) => (
//             <li key={experience.uuid}>{experience.name}</li>
//           ))}
//         </ul>
//       </div>

//       <h1>Experience Name-{experienceName}</h1>
//       <SlideShow slides={slides} models={models} />
//     </div>
//   );
// };

// export default App;
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
//import { useSelector } from "react-redux";
//import LoginForm from "./components/LoginForm";
import ExperienceList from "./components/ExperienceList";
import ExperienceDetail from "./components/ExperienceDetail";
import ViewExperience from "./components/ViewExperience";
import Login from "./components/Login";

const App = () => {
  //const idToken = useSelector((state) => state.auth.idToken);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/experiences"
            // element={!idToken ? <LoginForm /> : <ExperienceList />}
            element={<ExperienceList />}
          />
          <Route path="/experience/:id" element={<ExperienceDetail />} />
          <Route path="/view3dexperience/:id" element={<ViewExperience />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
