import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  models: [],
};

const modelSlice = createSlice({
  name: "model",
  initialState,
  reducers: {
    addModel: (state, action) => {
      const { asset_uuid, modelUrl, filename } = action.payload;
      state.models.push({ asset_uuid, modelUrl, filename });
    },
    resetModels: (state) => {
      state.models = [];
    },
  },
});

export const { addModel, resetModels } = modelSlice.actions;

export default modelSlice.reducer;
