import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import axios from "axios";
import "./Login.css"; // Import the CSS file
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebaseConfig/firebaseconfig";
import { loginWithFirebase } from "../features/authslice";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authStatus = useSelector((state) => state.auth.status);
  const authError = useSelector((state) => state.auth.error);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const idToken = await userCredential.user.getIdToken();
      console.log("userCredential", idToken);
      dispatch(loginWithFirebase(idToken))
        .unwrap()
        .then(() => {
          //console.log("my access token is", accessToken);
          navigate("/experiences"); // Navigate to the protected route upon successful login
        })
        .catch((error) => {
          setError(error.message);
        });
    } catch (error) {
      setError(error.message);
    }
  };
  // const handleLogin = async (e) => {
  //   e.preventDefault();
  //   navigate("/experiences");
  //   // const auth = getAuth();
  //   // try {
  //   //   const userCredential = await signInWithEmailAndPassword(
  //   //     auth,
  //   //     email,
  //   //     password
  //   //   );
  //   //   const idToken = await userCredential.user.getIdToken();
  //   //   const response = await axios.post(
  //   //     "https://api.tstvixr.in/v2/wonder/user/login",
  //   //     { id_tkn: idToken }
  //   //   );
  //   //   onLogin(response.data);
  //   // } catch (err) {
  //   //   setError("Invalid credentials");
  //   // }
  // };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin} className="login-form">
        <h2>Login</h2>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="login-button">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
