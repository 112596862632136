import axios from "axios";

const API_URL = "https://api.tstvixr.in/v2/wonder";
// export const fetchExperienceList = async (token) => {
//   console.log("calling Experience list");
//   const response = await axios.post(
//     `https://api.tstvixr.in/v2/tenant/justice/experience/list`,
//     {
//       project_id: "65cdc11390d5f786d2e7d55d",
//       // project_id: null,
//     },

//     {
//       headers: { Authorization: `Bearer:${token}` },
//     }
//   );
//   console.log("experience list data", response.data);
//   return response.data;
// };

export const fetchExperienceData = async (tenant_slug, uuid, token) => {
  const response = await axios.post(
    `https://wonder.tstvixr.in/v2/wonder/experience/get`,
    { tenant_slug, uuid },
    {
      responseType: "application/json",
      headers: { Authorization: `Bearer:${token}` },
    }
  );
  console.log("experience data", response.data);
  return response.data;
};

export const fetchAssetZip = async (tenant_slug, uuid, asset_uuid, token) => {
  const response = await axios.post(
    `https://api.tstvixr.in/v2/wonder/experience/downloadAsset`,
    { tenant_slug, uuid, asset_uuid },
    {
      responseType: "blob",
      headers: { Authorization: `Bearer:${token}` },
    }
  );
  console.log("asset data", response.data);
  return response.data;
};
