import { configureStore } from "@reduxjs/toolkit";
import experienceReducer from "../features/experienceSlice";
import expPageReducer from "../features/expPageSlice";
import expObjectReducer from "../features/expObjectSlice";
import voiceoverReducer from "../features/voiceoverSlice";
import modelReducer from "../features/modelSlice";
import authReducer from "../features/authslice";
const store = configureStore({
  reducer: {
    auth: authReducer,
    experienceList: experienceReducer,
    expPage: expPageReducer,
    expObject: expObjectReducer,
    voiceover: voiceoverReducer,
    model: modelReducer,
  },
});

export default store;
