// src/expPageSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expPages: [],
  currentPageIndex: 0,
};

const expPageSlice = createSlice({
  name: "expPage",
  initialState,
  reducers: {
    setPages: (state, action) => {
      state.expPages = action.payload;
      console.log("Pages are", state.expPages);
    },
    setCurrentPageIndex: (state, action) => {
      state.currentPageIndex = action.payload;
    },
    updatePage: (state, action) => {
      const { pageIndex, pageData } = action.payload;
      if (state.expPages[pageIndex]) {
        state.expPages[pageIndex] = {
          ...state.expPages[pageIndex],
          ...pageData,
        };
      }
    },
  },
});

export const { setPages, setCurrentPageIndex, updatePage } =
  expPageSlice.actions;

export default expPageSlice.reducer;
