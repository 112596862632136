import React, { useEffect, useState } from "react";
import Slide from "./Slide";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Html, useTexture } from "@react-three/drei";
import { XR, createXRStore } from "@react-three/xr";
import Experience from "./Experience";
import { useDispatch, useSelector } from "react-redux";
import { addVoiceover } from "../features/voiceoverSlice";
import { addModel, resetModels } from "../features/modelSlice";
import { fetchAndUnzipModel } from "../utils/modelUtils";
import "./Experience.css";
import { fetchAndUnzipVoiceover } from "../utils/voiceoverUtils";
import Voiceover from "./Voiceover";
import { useSpring, animated } from "@react-spring/three";
import Label from "./Label";
import { FaVolumeUp, FaVolumeMute } from "react-icons/fa"; // Import volume icons
import { useParams } from "react-router-dom";

const SlideShow = () => {
  const dispatch = useDispatch();
  const [isPlaying, setIsPlaying] = useState(false);
  const togglePlayback = () => {
    setIsPlaying((prev) => !prev);
  };
  const voiceovers = useSelector((state) => state.voiceover.voiceovers);
  const store = createXRStore();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentModelId, setCurrentModelId] = useState(null);
  const [expObjectBehaviouralProperties, setExpObjectBehaviouralProperties] =
    useState(null);
  const [ExpObjectBehaviourType, setExpObjectBehaviourType] = useState(null);
  const [model_3d_parts, setModel_3d_parts] = useState(null);
  const [experienceDescription, setExperienceDescription] = useState(null);
  const slides = useSelector((state) => state.expPage.expPages);
  const models = useSelector((state) => state.expObject.expObjects);
  const [voiceoverAssetUrl, setVoiceoverAssetUrl] = useState(
    voiceovers[0]?.voiceoverUrl
  );
  const [modelUrl, setModelUrl] = useState(null);
  const [filename, setFilename] = useState(null);
  const [labels, setLabels] = useState([]);

  const access_token = useSelector((state) => state.auth.accessToken);

  const { id } = useParams();
  useEffect(() => {
    const fetchVoiceovers = async () => {
      for (const slide of slides) {
        const assetid =
          slide.ExpPageContent?.ExpPageVoiceOverData?.ExpObjectAssetData
            ?.ExpAssetId;

        if (assetid) {
          try {
            const { voiceoverUrl } = await fetchAndUnzipVoiceover(
              "test",
              `${id}`,
              assetid,
              access_token
            );
            dispatch(addVoiceover({ asset_uuid: assetid, voiceoverUrl }));
          } catch (error) {
            console.error("Error fetching voiceover:", error);
          }
        }
      }
    };

    fetchVoiceovers();
  }, [slides, access_token, dispatch]);

  useEffect(() => {
    const fetchModels = async () => {
      dispatch(resetModels());
      for (const slide of slides) {
        const expPageObjectId =
          slide.ExpPageContent?.ExpPageObjects[0]?.ExpPageObjectId || "N/A";
        const matchedModel = models.find(
          (model) => model?.ExpObjectId === expPageObjectId
        );

        if (matchedModel) {
          const asset_uuid = matchedModel?.ExpObjectAssetData?.ExpAssetId;
          try {
            const { modelUrl, filename } = await fetchAndUnzipModel(
              "test",
              `${id}`,
              asset_uuid,
              access_token
            );
            dispatch(addModel({ asset_uuid, modelUrl, filename }));
            console.log("model url", modelUrl);
            setModelUrl(modelUrl);
            setFilename(filename);
          } catch (error) {
            console.error("Error fetching model:", error);
          }
        }
      }
    };

    fetchModels();
  }, [slides, access_token, dispatch]);

  useEffect(() => {
    setVoiceoverAssetUrl(voiceovers[currentIndex]?.voiceoverUrl);
    // Fetch the ExpPageObjectId from the current slide
    const expPageObjectId =
      slides[currentIndex]?.ExpPageContent?.ExpPageObjects[0]
        ?.ExpPageObjectId || "nhe mila object id";
    console.log(`expPageObjectId ${currentIndex}`, slides[currentIndex]);
    const ExpPageObjectBehaviourId =
      slides[currentIndex]?.ExpPageContent?.ExpPageObjects[0]
        ?.ExpPageObjectBehaviourId || "nhe mila beahaviour";
    console.log("ExpPageObjectBehaviourId", ExpPageObjectBehaviourId);

    // Find the corresponding model in the models array
    const matchedModel = models.find(
      (model) => model?.ExpObjectId === expPageObjectId
    );
    if (matchedModel) {
      console.log("inside matched model");
      const matchedModelBehaviour =
        matchedModel.ExpObjectBehaviouralProperties.find(
          (modelbehaviour) =>
            modelbehaviour?.ExpObjectBehaviourId === ExpPageObjectBehaviourId
        );

      console.log("matchedModelBehaviour", matchedModelBehaviour);
      //console.log("matched model detail", matchedModel);
      setExpObjectBehaviouralProperties(matchedModelBehaviour);
    }

    if (matchedModel) {
      console.log("Model matched:", matchedModel);

      // Fetch the asset ID and set state for the matched model
      const currentModelId =
        matchedModel?.ExpObjectAssetData?.ExpAssetId || "N/A";
      setCurrentModelId(currentModelId);

      const model3DParts =
        matchedModel?.ExpObjectType === "3D"
          ? matchedModel?.ExpObjectPartDetails
          : "N/A";
      setModel_3d_parts(model3DParts);

      model3DParts.map((modelpart) => {
        const labeltext =
          modelpart.ExpObjectAnnotationDetails[0].AnnotationTextContent
            .ExpTextValue;
        const labelpositionOffset =
          modelpart.ExpObjectAnnotationDetails[0].AnnotationLabelOffest;
        const objectTransformPosition =
          modelpart.ExpObjectAnnotationDetails[0]
            .ActiveAnnotationObjectTransformProperties.ObjectTransformPosition;

        const labelPosition = {
          x: objectTransformPosition.x + labelpositionOffset.x,
          y: objectTransformPosition.y + labelpositionOffset.y,
          z: objectTransformPosition.z + labelpositionOffset.z,
        };

        console.log(labelPosition);
        console.log("label text", labeltext);
        setLabels((prevLabels) => [
          ...prevLabels,
          { labeltext: labeltext, labelPosition: labelPosition },
        ]);
      });

      // setLabelsPosition((prevLabelspos) => [...prevLabelspos, labelPosition]);

      const expObjectBehaviourType =
        matchedModel?.ExpObjectInteractionDetails?.[0]
          ?.ExpObjectBehaviourType ?? null;
      setExpObjectBehaviourType(expObjectBehaviourType);

      const currentModels = slides[
        currentIndex
      ]?.ExpPageContent?.ExpPageObjects?.map((pageObject) =>
        models.find(
          (model) =>
            model?.ExpObjectAssetData?.ExpAssetId ===
            pageObject?.ExpPageObjectId
        )
      ).filter(Boolean);

      // if (currentModels && currentModels.length > 0) {
      //   const modelIds = currentModels.map(
      //     (model) => model?.ExpObjectAssetData?.ExpAssetId
      //   );
      //   console.log("Model IDs:", modelIds);
      // } else {
      //   console.log("No models found for the current slide.");
      // }
    } else {
      console.log("No matching model found.");
    }

    // Update experience description
    const experienceDescription =
      slides[currentIndex]?.ExpPageDescription || null;
    setExperienceDescription(experienceDescription);
  }, [currentIndex, slides, models]);
  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  // useEffect(() => {
  //   const interval = setInterval(nextSlide, 20000); // Change slide every 20 seconds
  //   return () => clearInterval(interval); // Clear interval on component unmount
  // }, [currentIndex, slides.length]);
  const { position } = useSpring({
    position: [0, 0, currentIndex * -5],
    config: { mass: 1, tension: 170, friction: 26 },
  });
  function Plane() {
    const texture = useTexture("../images/planebg.png"); // Make sure to place your texture in the public folder
    return (
      <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.5, 0]}>
        <planeGeometry args={[10, 10, 10]} />
        <meshStandardMaterial map={texture} color="#ffffff" />
      </mesh>
    );
  }

  function BackgroundMusic() {
    const [audio] = useState(new Audio("../audio/bg.mp3")); // Initialize audio instance

    useEffect(() => {
      audio.volume = 0.3;
      const playMusic = async () => {
        try {
          await audio.play();
          audio.loop = true;
        } catch (error) {
          console.error("Error playing background music:", error);
        }
      };
      playMusic();
      return () => {
        audio.pause();
        audio.currentTime = 0;
      };
    }, [audio]);

    return null;
  }
  return (
    <div className="slide-container">
      <button className="enter-ar-button" onClick={() => store.enterAR()}>
        Enter AR
      </button>
      <div className="ar-container" style={{ width: "80vw", height: "80vh" }}>
        <animated.group position={position}>
          <Canvas
            style={{ width: "100%", height: "100%" }} // Canvas size
            // camera={{ position: [0, 2, 5] }} // Camera position
            shadows
            camera={{ position: [0, 2, 10], fov: 20 }}
          >
            {/* {labels &&
              labels.map((label, index) => (
                <Label label={label.labeltext} position={label.labelPosition} />
              ))} */}
            <Voiceover
              url={voiceoverAssetUrl}
              position={[0, 2, 5]}
              isPlaying={isPlaying}
            />
            <XR store={store}>
              {slides.length > 0 && (
                <>
                  <Experience
                    model_id={currentModelId}
                    modelUrl={modelUrl}
                    filename={filename}
                    ExpObjectBehaviouralProperties={
                      expObjectBehaviouralProperties
                    }
                    ExpObjectBehaviourType={ExpObjectBehaviourType}
                    model_3d_parts={model_3d_parts}
                  />
                </>
              )}
            </XR>
            <Plane />
            <OrbitControls />
          </Canvas>
          <BackgroundMusic />
        </animated.group>
      </div>

      <div className="overlay">
        <div className="overlay-content">
          <p className="overlay-description">{experienceDescription}</p>
        </div>

        <div className="footer-nav-bar">
          <button onClick={togglePlayback} style={{ marginLeft: 10 }}>
            {isPlaying ? <FaVolumeUp /> : <FaVolumeMute />}
          </button>
          <button className="nav-btn" onClick={prevSlide}>
            ←
          </button>
          <span className="slide-indicator">
            {currentIndex + 1} / {slides.length}
          </span>
          <button className="nav-btn" onClick={nextSlide}>
            →
          </button>
        </div>
      </div>
    </div>
  );
};

export default SlideShow;
