// firebaseconfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB3qbSFFNKG3Fv52JBe8b41sJzvAg48Npo",
  authDomain: "jigyasa-6c8a5.firebaseapp.com",
  projectId: "jigyasa-6c8a5",
  //storageBucket: "vixr-new.appspot.com",
  messagingSenderId: "980959671840",
  appId: "1:980959671840:web:6fd8cf56ab69f2c8c56e45",
  // measurementId: "G-HLF1CX8PYW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export { auth };
