// src/features/experience/experienceSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define initial state
const initialState = {
  experienceList: [],
  status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
  error: null, // Error message or details
};

// Async thunk for fetching experiences
const fetchExperienceList = createAsyncThunk(
  "experienceList/fetchExperienceList",
  async (token, { rejectWithValue }) => {
    try {
      console.log("Calling experience list API", token);
      const response = await axios.post(
        "https://wonder.tstvixr.in/v2/wonder/experience/list",
        {
          project_id: "65cdc11390d5f786d2e7d55d",
          tenant_slug: "test",
          // project_id: null, // Optional if needed
        },
        {
          headers: { Authorization: `Bearer:${token}` },
        }
      );
      console.log("Experience list data", response.data);
      return response.data.data.items;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const experienceSlice = createSlice({
  name: "experienceList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExperienceList.pending, (state) => {
        state.status = "loading"; // Set status to 'loading' when the request is in progress
        state.error = null; // Clear any previous errors
      })
      .addCase(fetchExperienceList.fulfilled, (state, action) => {
        state.status = "succeeded"; // Set status to 'succeeded' when the request is successful
        state.experienceList = action.payload; // Store the fetched data
      })
      .addCase(fetchExperienceList.rejected, (state, action) => {
        state.status = "failed"; // Set status to 'failed' when the request fails
        state.error = action.payload; // Store the error message
      });
  },
});

export default experienceSlice.reducer;
export { fetchExperienceList };
