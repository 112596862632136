import { useLoader } from "@react-three/fiber";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const loadModelByExtension = (zipEntryName) => {
  if (zipEntryName.endsWith(".fbx")) {
    return FBXLoader;
  } else if (zipEntryName.endsWith(".obj")) {
    return OBJLoader;
  } else if (zipEntryName.endsWith(".gltf") || zipEntryName.endsWith(".glb")) {
    return GLTFLoader;
  } else {
    throw new Error(`Unsupported file type for entry: ${zipEntryName}`);
  }
};

const useModelLoader = (url, filename) => {
  const Loader = loadModelByExtension(filename);
  return useLoader(Loader, url);
};

export default useModelLoader;
