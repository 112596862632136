import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  voiceovers: [], // Array to store voiceovers with asset_uuid and voiceoverUrl
};

const voiceoverSlice = createSlice({
  name: "voiceover",
  initialState,
  reducers: {
    addVoiceover: (state, action) => {
      const { asset_uuid, voiceoverUrl } = action.payload;
      // Check if the voiceover already exists
      const existingIndex = state.voiceovers.findIndex(
        (v) => v.asset_uuid === asset_uuid
      );
      if (existingIndex !== -1) {
        // Update existing voiceover
        state.voiceovers[existingIndex] = { asset_uuid, voiceoverUrl };
      } else {
        // Add new voiceover
        state.voiceovers.push({ asset_uuid, voiceoverUrl });
      }
    },
    removeVoiceover: (state, action) => {
      const { asset_uuid } = action.payload;
      state.voiceovers = state.voiceovers.filter(
        (v) => v.asset_uuid !== asset_uuid
      );
    },
    updateVoiceover: (state, action) => {
      const { asset_uuid, voiceoverUrl } = action.payload;
      const existingIndex = state.voiceovers.findIndex(
        (v) => v.asset_uuid === asset_uuid
      );
      if (existingIndex !== -1) {
        state.voiceovers[existingIndex] = { asset_uuid, voiceoverUrl };
      }
    },
  },
});

export const { addVoiceover, removeVoiceover, updateVoiceover } =
  voiceoverSlice.actions;

export default voiceoverSlice.reducer;
