// authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { authenticateWithServer } from "../api";

const initialState = {
  accessToken: null,
  status: "idle",
  error: null,
};

export const loginWithFirebase = createAsyncThunk(
  "auth/loginWithFirebase",
  async (idToken, { rejectWithValue }) => {
    try {
      const accessToken = await authenticateWithServer(idToken);
      console.log("my access token is", accessToken);
      return accessToken;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      state.accessToken = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginWithFirebase.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginWithFirebase.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.accessToken = action.payload;
      })
      .addCase(loginWithFirebase.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
