import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchExperienceList } from "../features/experienceSlice";
import { useNavigate } from "react-router-dom";
import "./ExperienceList.css";

const ExperienceList = ({ onSelect }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.auth.accessToken);
  console.log("accessToken----", accessToken);
  // Access Redux state
  const experienceList = useSelector(
    (state) => state.experienceList.experienceList
  );
  const status = useSelector((state) => state.experienceList.status);
  const error = useSelector((state) => state.experienceList.error);

  // Replace with your actual token

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchExperienceList(accessToken));
    }
  }, [status, dispatch, accessToken]);

  // Handle different states
  if (status === "loading") return <p>Loading...</p>;
  if (status === "failed") return <p>Error: {error}</p>;

  return (
    <div className="experience-list">
      <h2 className="title">My Experiences</h2>
      <div className="cards-container">
        {experienceList.length === 0 ? (
          <p>No experiences available</p>
        ) : (
          experienceList.map((experience) => (
            <div
              key={experience.uuid}
              className="card"
              onClick={() => {
                if (onSelect) onSelect(experience);
                navigate(`/experience/${experience.uuid}`);
              }}
            >
              <h3>{experience.name}</h3>
              <img
                src={`data:image/png;base64,${experience.icon}`}
                alt="icon"
                style={{
                  position: "relative",
                  top: -95,
                  left: -24,
                  width: 60,
                  height: 60,
                  borderRadius: 10,
                }}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ExperienceList;
